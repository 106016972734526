import { Swiper, Navigation, Pagination, Autoplay } from 'swiper/js/swiper.esm.js';

Swiper.use([ Navigation, Pagination, Autoplay ]);
	
/*/
 *  Slider
/*/
	let swiperSlideLoop = document.querySelectorAll('[bb-slider] .swiper-slide').length > 1 ? true : false
	let swiperSlider = new Swiper('[bb-slider]', {
		loop: swiperSlideLoop,
		speed: 1000,
		slidesPerView: 1,
		autoHeight: false,
		observer: true,
		threshold: 5,
		watchOverflow: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
		navigation: {
			nextEl: '.swiper-slider-next',
			prevEl: '.swiper-slider-prev',
		}
	})


/*/
 *  Products
/*/
	let swiperProducts = new Swiper('[bb-products]', {
		loop: true,
		speed: 1000,
		slidesPerView: 'auto',
		centeredSlides: true,
		autoHeight: false,
		observer: true,
		threshold: 5,
		watchOverflow: true,
		loopAdditionalSlides: 2,
		loopedSlides: 2,
		autoplay: {
			delay: 2000,
			disableOnInteraction: true
		},
		on: {
			slideChangeTransitionStart: function () {
				let currentSlide = this.slides[this.activeIndex]
				let route = currentSlide.dataset.route
				let color = currentSlide.dataset.color
	
				document.querySelector('.sc--home_products').style.setProperty('--cl-product', color)
				document.querySelector('.sc--home_products_btn').href = route
			}
		}
	})
	
	// $('.sc--home_products').hover(function() {
	// 	swiperProducts.autoplay.stop()
	// }, function() {
	// 	swiperProducts.autoplay.start()
	// })


/*/
 *  Clients
/*/
	let swiperClients = new Swiper('[bb-clients]', {
		loop: true,
		speed: 1000,
		slidesPerView: 'auto',
		spaceBetween: 30,
		centeredSlides: true,
		autoHeight: false,
		observer: true,
		threshold: 5,
		watchOverflow: true,
		autoplay: {
			delay: 2000,
			disableOnInteraction: false
		},
	})